.react-calendar-timeline * {
    box-sizing: border-box;
}

.react-calendar-timeline .rct-outer {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    border-bottom: 1px solid rgba(81, 81, 81, 1);
    border-right: 1px solid rgba(81, 81, 81, 1);
    border-left: 1px solid rgba(81, 81, 81, 1);
    /* border: 1px solid #bbb; */
    /* border-top: 0px; */
}

.react-calendar-timeline .rct-scroll {
    display: inline-block;
    white-space: normal;
    vertical-align: top;
    overflow-x: hidden;
    overflow-y: hidden;
    -ms-touch-action: none;
    touch-action: none;
}

.react-calendar-timeline .rct-item:hover {
    z-index: 88;
}

.react-calendar-timeline .rct-item.item {
    background: #21f32c;
    border-color: #29b31a;
}

/* .react-calendar-timeline .rct-item .rct-item-content {
  position: sticky;
  position: -webkit-sticky;
  left: 0px;
  overflow: hidden;
  display: inline-block;
  border-radius: 2px;
  padding: 0 6px;
  height: 100%;
  color: #000000; } */

.react-calendar-timeline .rct-sidebar {
    overflow: hidden;
    white-space: normal;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    align-items: center;
    align-content: center;
    position: relative;
    /* box-sizing: border-box; */
}
.css-dhopo2 {
    min-height: 200px;
}

/* .react-calendar-timeline .rct-sidebar.rct-sidebar-right {
  border-right: 0;
  border-left: 1px solid #bbb; } */
.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
    padding: 0 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    margin: 0;
    border-bottom: 1px solid rgba(81, 81, 81, 1);
    border-right: 1px solid rgba(81, 81, 81, 1);
    /*
    border-left: 1px solid #bbb;  */
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
    background: rgba(27, 32, 38, 1);
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
    background: rgba(27, 32, 38, 1);
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
    position: absolute;
    /* border-left: 1px solid #bbb; */
    z-index: 30;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first {
    /* border-left-width: 2px;  */
}

/* weekends */
/* .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6, .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
  background: rgba(27, 32, 38, 1); } */

.react-calendar-timeline .rct-horizontal-lines {
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    border-bottom: 1px solid rgba(81, 81, 81, 1);
    box-sizing: border-box;
    z-index: 40;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    background: rgba(27, 32, 38, 1);
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
    background: rgba(27, 32, 38, 1);
}

.react-calendar-timeline .rct-cursor-line {
    position: absolute;
    width: 2px;
    background: #ffc200;
    z-index: 51;
}

.react-calendar-timeline .rct-dateHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    /* border-bottom: 1px solid #4B6F83; */
    font-size: 14px;
    background-color: rgba(27, 32, 38, 1);
    border-left: 1px solid rgba(81, 81, 81, 1);
    border-top: 1px solid rgba(81, 81, 81, 1);
    border-right: 1px solid rgba(81, 81, 81, 1);
}

.react-calendar-timeline .rct-dateHeader-primary {
    background-color: rgba(27, 32, 38, 1);

    /* border-left: 1px solid #bbb;
    border-right: 1px solid #bbb;  */
    color: #fff;
}

.react-calendar-timeline .rct-header-root {
    background: rgba(27, 32, 38, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(81, 81, 81, 1);
    border-left: 1px solid rgba(81, 81, 81, 1);
    border-top: 1px solid rgba(81, 81, 81, 1);
    /* border-bottom: 1px solid #4B6F83; */
    border-bottom: 1px solid rgba(81, 81, 81, 1);
    /* border-bottom: 1px solid rgb(75, 111, 131); */
    /* border-top: 1px solid #bbb;
    border-left: 1px solid #bbb;  */
    /* border-bottom: 1px solid; */
}

/* .react-calendar-timeline .rct-calendar-header {
   border-right: 1px solid #bbb;
 } */
