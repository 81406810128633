.container {
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
    height: 40vh;
}

.container > p {
    font-size: 1rem;
}

.container > em {
    font-size: 0.8rem;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    /*background-color: #9d9d9d ;*/
    /*opacity: 50%;*/
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
}

.dropzone:focus {
    border-color: #2196f3;
}

.dropzone.disabled {
    opacity: 0.6;
}
